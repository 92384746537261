<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex" :rules="formRules">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="仓库" :class="$i18n.locale" prop="warehouseId">
              <el-select
                v-model="form.warehouseId"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                @change="form.siteGroupId=''"
              >
                <el-option
                  v-for="item in warehouseOption"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="库存组合" :class="$i18n.locale" prop="siteGroupId">
              <el-select
                v-model="form.siteGroupId"
                clearable
                filterable
                :loading="selectLoading"
                :placeholder="$t('page.selectPlaceholder')"
                @visible-change="_findGroupAllocation"
              >
                <el-option
                  v-for="item in siteGroupList"
                  :key="item.groupId"
                  :label="item.groupName"
                  :value="item.groupId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="STYLE" :class="$i18n.locale">
              <el-select v-model="form.style" value-key="id" clearable filterable>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="COLOR" :class="$i18n.locale">
              <el-select v-model="form.color" value-key="id" clearable filterable @visible-change="_queryColorList">
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIZE" :class="$i18n.locale">
              <el-select v-model="form.sizes" value-key="id" clearable filterable @visible-change="_querySizeList">
                <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!--     <el-row class="mb-3">
      <el-upload
        ref="uploadForm"
        action=""
        class="el-button padding-none"
        :http-request="uploadFile"
        :limit="1"
        :show-file-list="false"
        :file-list="fileList"
        :auto-upload="false"
        :on-change="fileChange"
      >
        <el-button type="primary">导入</el-button>
      </el-upload>
      <ImportButton export-key="" :params="form" />
    </el-row> -->
    <el-table
      ref="table"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
      class="mt-3"
      @selection-change="SelectionChange"
    >
      <el-table-column type="selection" width="80" align="center" />

      <el-table-column prop="style" label="Style" min-width="120" />
      <el-table-column prop="color" label="Color" min-width="120" />
      <el-table-column prop="size" label="Size" min-width="120" />
      <el-table-column prop="allotPairs" label="库存组合库存数" min-width="180" />
      <el-table-column label="安全库存组合" min-width="180">
        <template slot-scope="scope">
          <el-input v-model="scope.row.safePairs" oninput="value=value.replace(/[^0-9.]/g,'')" @input="input(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />
    <el-row class="mt-4">
      <el-col :offset="11" :span="2"><el-button type="primary" :loading="loading" @click="submit">确认分配</el-button></el-col>
    </el-row>

  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import { queryStyleList, querySizeList, queryColorList, queryWarehouseList } from '@/api/scm-api'
import { pageSafeInventory, getGroupByWarehouseId, safeAllocation } from '@/api/safestock'
export default {
  components: { Paging },
  data() {
    return {
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      selectLoading: false,
      loading: false,
      multipleTableSelection: [],
      siteGroupList: [],
      form: {
        siteGroupId: '',
        warehouseId: ''
      },
      tableDatas: [],
      tableDatasLoading: false,
      warehouseOption: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      fileList: [],
      formRules: {
        warehouseId: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: ['blur', 'change'] }
        ],
        siteGroupId: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    queryParams() {
      return Object.assign({}, this.pager, this.form)
    },
    alloctionParams() {
      let items = []
      items = this.multipleTableSelection.map(item => {
        return { pairs: item.safePairs, sku: item.sku, style: item.style, color: item.color, size: item.size }
      })
      const { siteGroupId, warehouseId } = this.form
      return Object.assign({ siteGroupId, warehouseId }, { items })
    }
  },
  created() {

  },
  mounted() {
    this._queryStyleList()
    this._queryWarehouseList()
    this._findGroupAllocation()
  },
  methods: {
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    input(row) {
      row.safePairs > 0 && row.safePairs <= row.allotPairs ? this.$refs.table.toggleRowSelection(row, true)
        : this.$refs.table.toggleRowSelection(row, false)
    },
    submit() {
      if (this.multipleTableSelection.length === 0) {
        this.$message.warning('请至少选中一条数据')
        return
      }
      if (this.multipleTableSelection.some(item => item.safePairs > item.allotPairs)) {
        this.$message.warning('存在安全库存组合数据大于库存组合库存数,请核对')
        return
      }
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            this.loading = true
            const { code, msg } = await safeAllocation(this.alloctionParams)
            if (code === 0) {
              this.loading = false
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$refs.table.clearSelection()
              this.queryClick(1)
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        } else {
          return false
        }
      })
    },
    SelectionChange(val) {
      this.multipleTableSelection = val
    },
    async _findGroupAllocation() {
      this.siteGroupList = []
      try {
        this.selectLoading = true
        const { datas } = await getGroupByWarehouseId({ warehouseId: this.form.warehouseId })
        this.siteGroupList = datas
      } finally {
        this.selectLoading = false
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
    },
    queryClick(flag) {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            flag && flag === 1 ? this.pager.current = 1 : ''
            this.tableDatasLoading = true
            const { datas: { records, pager }, code } = await pageSafeInventory(this.queryParams)
            if (code === 0) {
              this.pager = pager
              this.tableDatas = records
              this.tableDatasLoading = false
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.tableDatasLoading = false
          }
        } else {
          return false
        }
      })
    },
    // size
    async _querySizeList() {
      const { id } = this.styleOptions.find(item => item.styleName === this.form.style) || []

      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // style
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // color
    async _queryColorList() {
      const { id } = this.styleOptions.find(item => item.styleName === this.form.style) || []
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseOption = datas
    }

  }
}
</script>

<style scoped lang="scss">

</style>
